import React, { useEffect, useState } from "react";

import SidebarButtons from "@components/modules/content-header-image/_content-header-image-btns-alt";

const ContactSidebarBtns = (props) => {
    const btnsArr = props.ButtonData
    const [showBtn, setShowBtn] = useState(false)
    const [btnNewArray, setbtnNewArray] = useState(btnsArr)
    
    useEffect(() => {
        function updateBtnClassInArray(array) {
            return array.map((item, index) => {
                if (index !== 0) {
                    // modify the btn class value
                    // if more then 1 button we add different class
                    return {
                        ...item,
                        ...item.btnClass = props.btnClass2
                    }
                } else {
                    return {
                        ...item,
                        ...item.btnClass = props.btnClass
                    }
                }
            })
        }
        setbtnNewArray(updateBtnClassInArray(props.ButtonData))
        setShowBtn(true)
    },[props.ButtonData, props.btnClass, props.btnClass2])

    return(
        showBtn && <SidebarButtons ButtonData={btnNewArray} />
    )
}

export default ContactSidebarBtns