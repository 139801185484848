import React from 'react';
// import Button from 'react-bootstrap/Button'
import SocialShare from '@components/social-share/';

// import SaveIcon from '@icons/heart.inline.svg';

export default function ContactSidebarSave(props) {
    return (
        <div className={props.SaveBtnClass}>
            {/* <Button variant="none" className="save-btn btn-none py-0 px-15 px-sm-25"><SaveIcon /> Save Job</Button> */}
            <SocialShare
                pageLocation={props.pageLocation ? props.pageLocation : null}
                pageTitle={props.pageTitle ? props.pageTitle : null}
            />
        </div>
    );
}