import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import VideoModal from "@components/modal/video-modal-img";
import CruxHR_Img from "@images/crux_hr.png";
import { urlencoded } from 'body-parser';

export default function ContactSidebarImage(props) {

    let path_name = props.pageLocation && (props.pageLocation.pathname).split('/');
    let get_url = path_name && path_name.length > 0 && (path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2]);


    return (
        <div className="module-contact-sidebar__image">
            {props.showVideo ? (
                <VideoModal
                    imgUrl={props.imgUrl}
                    imgClass=" "
                    showVideo={props.showVideo}
                    videoId={props.videoUrl}
                    videoChannel={props.videoChannel}
                    hideCircleIcon={true}
                />
            ) : (
                props.videoask ? (
                    <div className="videoask-frame">
                        <iframe src={props.videoaskUrl ? props.videoaskUrl : ""} title="crux careers" allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" width="100%" height="330px" border="0"></iframe>
                    </div>
                ) : (
                    
                    props.imgUrl ? (
                        get_url && get_url === 'crux-hr' ? (
                            <a href="https://portal-attwells.com/" target="_blank">
                                <BackgroundImage
                                    Tag="div"
                                    className={`${props.imageClass} ${get_url && get_url === 'crux-hr' ? 'no_sidebarr_bg' : ''}`}
                                    fluid={props.imgUrl}
                                    loading="eager"
                                    role="img"
                                />
                            </a>
                        ) : (
                            <BackgroundImage
                                Tag="div"
                                className={`${props.imageClass} ${get_url && get_url === 'crux-hr' ? 'no_sidebarr_bg' : ''}`}
                                fluid={props.imgUrl}
                                loading="eager"
                                role="img"
                            />
                        )
                        
                    ) : (
                        get_url && get_url === 'crux-hr' && (
                            <a href="https://portal-attwells.com/" target="_blank">
                                <div className="module-contact-sidebar__image no_sidebarr_bg" style={{backgroundImage:`url(https://ggfx-cruxcareers.s3.eu-west-2.amazonaws.com/i.dev2/Crux_HR_8becc736a4.png)`, backgroundSize:'contain'}}></div>
                            </a>
                            
                        )
                        
                    )
                )
            )}

        </div>
    );
}